import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Space, Row, Col, Table, Typography } from 'antd';
import { CloseOutlined, DragOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const { Title } = Typography;
const { Option } = Select;

// Drag handle component
const DragHandle = SortableHandle(() => (
    <DragOutlined className='item-action-btn-drag' onMouseDown={(e) => e.preventDefault()} />
));
// Function to move an item in an array
const moveItemInArray = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [removed] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, removed);
    return newArray;
};

// Sortable item component
const SortableItem = SortableElement((props) => <tr {...props} />);

// Sortable body container
const SortableBody = SortableContainer((props) => <tbody {...props} />);


const CustomOptions = ({ sendDataToParent, currentData }) => {
    const [form] = Form.useForm();
    const [formFields, setFormFields] = useState(currentData?.custom_options || []);
    const [fieldType, setFieldType] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');
    const [fieldPlaceholder, setFieldPlaceholder] = useState('');

    useEffect(() => {
        sendDataToParent(formFields);
    }, [formFields, sendDataToParent]);

    const handleAddFields = () => {
        if (!fieldType) {
            NotificationManager.error('Please select a field type.');
            return;
        }

        if (!fieldLabel) {
            NotificationManager.error('Please enter a field label.');
            return;
        }

        const generatedFieldKey = fieldLabel.toLowerCase().replace(/ /g, '_');

        const isDuplicateKey = formFields.some(field => field.key === generatedFieldKey);

        if (isDuplicateKey) {
            NotificationManager.error('Field key already exists. Please enter a unique field key.');
            return;
        }

        const newFields = [{
            type: fieldType,
            label: fieldLabel,
            placeholder: fieldPlaceholder,
            key: generatedFieldKey,
        }];

        setFormFields([...formFields, ...newFields]);
        setFieldLabel('');
        setFieldPlaceholder('');
        sendDataToParent([...formFields, ...newFields]);
    };

    const handleRemoveField = (key) => {
        const updatedFields = formFields.filter(field => field.key !== key);
        setFormFields(updatedFields);
        sendDataToParent(updatedFields);
    };

    const onSortStart = () => {
        console.log('Sorting started...');
        // Add the noselect class to prevent text selection during drag
        document.body.classList.add('noselect');
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {

        // Remove the noselect class after sorting is done
        document.body.classList.remove('noselect');

        if (oldIndex !== newIndex) {
            // Move items in the array based on drag
            const newData = moveItemInArray(formFields.slice(), oldIndex, newIndex);

            // Map over the updated array to assign the new 'order' field
            const updatedData = newData.map((item, index) => ({
                ...item,
                order: index + 1, // Update order based on new index
            }));

            // Update form fields with the reordered data
            setFormFields(updatedData);
            sendDataToParent(updatedData);

            console.log('Sorted items: ', updatedData); // Log the sorted array for debugging
        }
    };

    // Draggable container component
    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    // Draggable body row component
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = formFields.findIndex((x) => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Field Type',
            dataIndex: 'type',
            key: 'type',
            width: 120,
            className: 'drag-visible',
        },
        {
            title: 'Field Key',
            dataIndex: 'key',
            key: 'key',
            className: 'drag-visible',
        },
        {
            title: 'Field Label',
            dataIndex: 'label',
            key: 'label',
            className: 'drag-visible',
        },
        {
            title: 'Field Placeholder',
            dataIndex: 'placeholder',
            key: 'placeholder',
            className: 'drag-visible',
        },
        {
            title: 'Action',
            key: 'action',
            width: 50,
            align: 'center',
            className: 'drag-visible',
            render: (text, record) => (
                <Space size="middle">
                    <CloseOutlined
                        className='item-action-btn-remove'
                        onClick={() => handleRemoveField(record.key)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <>
            <Form form={form} autoComplete="off" layout='vertical' className='mb-4'>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Title level={5} className="mt-3 mb-3">Select fields for custom field setup</Title>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Field Type">
                            <Select
                                value={fieldType || undefined}
                                onChange={setFieldType}
                                placeholder="Select field type"
                                className='w-100'
                            >
                                <Option value="upload">Upload Image</Option>
                                <Option value="text">Text Input</Option>
                                <Option value="textarea">Text Area</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Field Label">
                            <Input
                                value={fieldLabel}
                                onChange={(e) => setFieldLabel(e.target.value)}
                                placeholder="Enter field label"
                                className='w-100'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Field Placeholder">
                            <Input
                                value={fieldPlaceholder}
                                onChange={(e) => setFieldPlaceholder(e.target.value)}
                                placeholder="Enter field placeholder"
                                className='w-100'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" onClick={handleAddFields}>
                                Create Fields
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    size='small'
                    columns={columns}
                    dataSource={formFields}
                    rowKey="key"
                    pagination={false}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            </Form>
        </>
    );
};

export default CustomOptions;

//https://www.npmjs.com/package/react-nestable#options
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Card, Col, Collapse, Divider, Empty, Input, Modal, Row, Space, Typography } from 'antd';
import TableActionBar from '../../components/TableActionBar';
import PageTitle from '../../components/PageTitle';
import qs from 'qs';
import RenderCategoryTree from '../../components/RenderCategoryTree';
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import BaseSelect from '../../components/Elements/BaseSelect';
import { isMobile } from 'react-device-detect';
import { DeleteOutlined, EditOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

import { getCategoriesActive } from '../../redux/actions/ProductCategoriesAction';
import {
    getMenus, createMenus,
    getSpecificMenus, addCategoriesToMenu,
    removeMenusItems, getSpecificMenuItems,
    updateMenuItem, updateAllMenuItems,
    addCustomItemToMenu
} from '../../redux/actions/ManageMenusAction'


const { Title } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/manage-menus',
        breadcrumbName: 'Manage Menus',
    },
];
const { confirm } = Modal;
const { Panel } = Collapse;

class ManageMenus extends Component {
    constructor() {
        super();
        this.state = {
            openMenuForm: false,
            loadingCreateMenu: false,
            menu_items: [],
            current_menu: null,
            currentItem: null,
            checkedItems: [],
            loadingAddtoMenu: false,
            openMenuItemForm: false
        };
    }
    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.props.getCategoriesActive();
        this.props.getMenus();
        this.getMenu(query?.menu_id);
    }

    uncheckAllCategories = () => {
        // Gọi hàm handleUncheckAll từ component RenderCategoryTree
        this.categoryTreeRef.handleUncheckAll();
    }

    handleAddCategoriesToMenu() {
        this.setState({ loadingAddtoMenu: true });

        const { checkedItems, current_menu } = this.state;
        const { addCategoriesToMenu } = this.props;

        addCategoriesToMenu({ category_ids: checkedItems, menu_id: current_menu?.id })
            .then(res => {
                this.setState({ loadingAddtoMenu: false, checkedItems: [] });
                this.getMenu(current_menu?.id);
                this.uncheckAllCategories();
            })
            .catch(err => {
                console.error("Error adding categories to menu:", err);
                this.setState({ loadingAddtoMenu: false });
            });
    }

    handleAddCustomItemToMenu(e) {
        this.props.form.validateFields(['label', 'link'], (err, values) => {
            if (!err) {
                const { current_menu } = this.state;
                const { addCustomItemToMenu } = this.props;
                addCustomItemToMenu({
                    menu_id: current_menu?.id,
                    label: values.label,
                    link: values.link
                }).then(res => {
                    this.getMenu(current_menu?.id);
                    this.props.form.resetFields();
                }).catch(err => {
                    console.error("Error adding custom item to menu:", err);
                });
            }
        })
    }

    handleCreateNewMenu(e) {
        e.preventDefault();
        this.props.form.validateFields(['menu_name'], (err, values) => {
            if (!err) {
                this.props.createMenus({ name: values.menu_name }).then(res => {
                    this.props.getMenus();
                    this.onCancel();
                })
            }
        })
    }

    handleChangeCategoryItems = (checkedItems) => {
        console.log("checkedItems: ", checkedItems)
        this.setState({ checkedItems })
    };

    handleChangeMenu = (id) => {
        var query = { menu_id: id }
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
        this.getMenu(id);
    }

    getMenu(menu_id) {
        this.props.getSpecificMenus(menu_id).then(res => {
            this.setState({ menu_items: res?.items, current_menu: res })
        }).catch(err => {
            this.setState({ menu_items: [], current_menu: [] })
        });
    }

    handleChangeMenuItems = (items) => {
        const { current_menu } = this.state;
        this.props.updateAllMenuItems(current_menu?.id, items).then(res => {
            this.getMenu(current_menu?.id);
        })
    }

    onOpenEditItem(id) {
        this.props.getSpecificMenuItems(id).then(res => {
            this.setState({ currentItem: res, openMenuItemForm: true })
        }).catch(err => {
            console.log(err)
        })
    }

    handleEditItem(e) {
        e.preventDefault();
        this.props.form.validateFields(['item_label', 'item_link'], (err, values) => {
            if (!err) {
                const { currentItem } = this.state;
                values.link = values.item_link;
                values.label = values.item_label;
                delete values.item_link;
                delete values.item_label;
                this.props.updateMenuItem(currentItem?.id, values).then(res => {
                    this.getMenu(currentItem?.menu_id);
                    this.onCancel();
                })
            }
        })
    }

    handleDeleteItem(id) {
        confirm({
            title: 'Warning',
            content: 'Are you sure you want to delete this item? This action cannot be undone.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => this.props.removeMenusItems([id]).then(() => {
                this.handleChangeMenu(this.state.current_menu?.id);
            }),
            onCancel() { },
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ openMenuForm: false, openMenuItemForm: false })
    }

    render() {
        const { openMenuForm, openMenuItemForm, loadingCreateMenu, menu_items, current_menu, currentItem } = this.state;
        const { categories_active_list, menus } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <PageTitle routes={routes} title="Menus" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showFilter={false}
                        showSearch={false}
                    >
                    </TableActionBar>
                </div>

                <Card>
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Space align="center" wrap>
                                    Select a menu to edit
                                    <BaseSelect
                                        options={menus || []}
                                        optionValue="id"
                                        optionLabel="name"
                                        placeholder="Select menu"
                                        defaultValue={parseInt(query.menu_id) || null}
                                        showSearch
                                        style={{ width: '200px' }}
                                        onChange={(id) => this.handleChangeMenu(id)}
                                    />
                                    <Button onClick={() => this.setState({ openMenuForm: true })} className='primary-color' type='dashed'>or create a new menu</Button>
                                </Space>
                            </Col>
                        </Row>
                        <Divider className='mb-4 mt-4' />
                        <Row gutter={16}>
                            <Col xs={24} md={8} className='mb-3'>
                                <Title level={5} className='mb-3'>Add menu items</Title>
                                <Collapse accordion defaultActiveKey={['1']}>
                                    <Panel header={<strong>Product categories</strong>} key="1">
                                        <div className='wrap-category-tree'>
                                            <RenderCategoryTree
                                                items={categories_active_list || []}
                                                parentId={0}
                                                onChangeCheckedItems={this.handleChangeCategoryItems}
                                                ref={ref => (this.categoryTreeRef = ref)} // Tạo ref để truy cập component RenderCategoryTree từ component cha
                                            />
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <Divider />
                                            <Button onClick={(e) => this.handleAddCategoriesToMenu(e)} type='default' htmlType='submit'>Add to menu</Button>
                                        </div>
                                    </Panel>
                                    <Panel header={<strong>Custom links</strong>} key="2">
                                        <Form.Item label="Label">
                                            {getFieldDecorator('label', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Field is required!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="Link">
                                            {getFieldDecorator('link', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Field is required!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(<Input placeholder='https://' />)}
                                        </Form.Item>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button onClick={(e) => this.handleAddCustomItemToMenu(e)} type='default' htmlType='submit'>Add to menu</Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </Col>
                            <Col xs={24} md={16}>
                                <Title level={5} className='mb-3'>Menu structure</Title>
                                <Card
                                    size='small'
                                    headStyle={{ background: '#fafafa' }}
                                    title={
                                        <Space align="center" wrap>
                                            <strong>Menu name</strong>
                                            {getFieldDecorator('current_menu_mane', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Field is required!',
                                                    },
                                                ],
                                                initialValue: current_menu?.name
                                            })(<Input disabled />)}
                                        </Space>
                                    }
                                >
                                    {
                                        menu_items && menu_items.length ? (
                                            <>
                                                <Nestable
                                                    maxDepth={3}
                                                    items={menu_items || []}
                                                    className="nestable-menu-ecom"
                                                    onChange={(items) => this.handleChangeMenuItems(items)}
                                                    renderCollapseIcon={({ isCollapsed }) =>
                                                        isCollapsed ? (
                                                            <PlusSquareOutlined className="iconCollapse" />
                                                        ) : (
                                                            <MinusSquareOutlined className="iconCollapse" />
                                                        )
                                                    }
                                                    renderItem={({ item, collapseIcon }) => (
                                                        <>
                                                            <div className="listMenu">
                                                                <div>
                                                                    {collapseIcon}
                                                                    {item.label}
                                                                </div>
                                                                <div>
                                                                    <EditOutlined
                                                                        className='item-action-btn'
                                                                        onClick={() => this.onOpenEditItem(item.id)}
                                                                    />
                                                                    <Divider type='vertical' />
                                                                    <DeleteOutlined
                                                                        className='item-action-btn-remove'
                                                                        onClick={() => this.handleDeleteItem(item.id)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </>
                                        ) : (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        )
                                    }

                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Card>

                <Modal
                    title="Create a new menu"
                    visible={openMenuForm}
                    header={false}
                    onOk={(e) => this.handleCreateNewMenu(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={loadingCreateMenu}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.handleCreateNewMenu(e)}
                        >
                            Create menu
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Menu name">
                                    {getFieldDecorator("menu_name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    title="Update menu items"
                    visible={openMenuItemForm}
                    header={false}
                    onOk={(e) => this.handleEditItem(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={(e) => this.handleEditItem(e)}
                        >
                            Update item
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Label">
                                    {getFieldDecorator("item_label", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required",
                                            },
                                        ],
                                        initialValue: currentItem?.label
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Link">
                                    {getFieldDecorator("item_link", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required",
                                            },
                                        ],
                                        initialValue: currentItem?.link
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        categories_active_list: state.ProductCategories.categories_active_list,
        menus: state.ManageMenusReducer.menus
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCategoriesActive: (filter) => dispatch(getCategoriesActive(filter)),
        getMenus: (filter) => dispatch(getMenus(filter)),
        createMenus: (data) => dispatch(createMenus(data)),
        getSpecificMenus: (id) => dispatch(getSpecificMenus(id)),
        addCategoriesToMenu: (data) => dispatch(addCategoriesToMenu(data)),
        removeMenusItems: (ids) => dispatch(removeMenusItems(ids)),
        getSpecificMenuItems: (id) => dispatch(getSpecificMenuItems(id)),
        updateMenuItem: (id, data) => dispatch(updateMenuItem(id, data)),
        updateAllMenuItems: (menu_id, data) => dispatch(updateAllMenuItems(menu_id, data)),
        addCustomItemToMenu: (data) => dispatch(addCustomItemToMenu(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ManageMenus' })(ManageMenus));

import api from '../../utils/api';
import { NotificationManager } from 'react-notifications';

import {
    GET_MENUS,
    GET_SPECIFIC_MENUS,
    UPDATE_SPECIFIC_MENUS,
    CREATE_NEW_MENUS,
    REMOVE_MENUS,
    ADD_CATEGORIES_TO_MENUS,
    REMOVE_MENUS_ITEMS,
    GET_SPECIFIC_MENU_ITEMS,
    UPDATE_SPECIFIC_MENU_ITEMS,
    UPDATE_SPECIFIC_All_MENU_ITEMS,
    ADD_CUSTOM_ITEM_TO_MENUS
} from './types';

export const getMenus = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/manage-menus', { params: filter }).then(res => {
            dispatch({ type: GET_MENUS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificMenus = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/manage-menus/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_MENUS, payload: res.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createMenus = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/manage-menus', data).then(res => {
            dispatch({ type: CREATE_NEW_MENUS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateMenus = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/manage-menus/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_MENUS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeMenus = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/manage-menus`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_MENUS, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


export const addCategoriesToMenu = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/manage-menus/add/categories', data).then(res => {
            dispatch({ type: ADD_CATEGORIES_TO_MENUS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const addCustomItemToMenu = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/manage-menus/add/custom/item', data).then(res => {
            dispatch({ type: ADD_CUSTOM_ITEM_TO_MENUS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificMenuItems = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/manage-menus/detail/item/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_MENU_ITEMS, payload: res.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateMenuItem = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/manage-menus/update/item/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_MENU_ITEMS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateAllMenuItems = (menu_id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/manage-menus/update/items/${menu_id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_All_MENU_ITEMS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeMenusItems = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/manage-menus/delete/items`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_MENUS_ITEMS, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
import React from 'react';
import { Checkbox } from 'antd';

class RenderCategoryTree extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checkedList: [],
        };
    }

    changeCheckBox(value) {
        this.setState({ checkedList: value });
        this.props.onChangeCheckedItems([...value]);
    }

    handleUncheckAll = () => {
        this.setState({ checkedList: [] });
        this.props.onChangeCheckedItems([]);
    };

    renderCategory(item, index) {
        const { items } = this.props;
        const filteredItems = items.filter(i => i.parent === item.id);

        if (!filteredItems || filteredItems.length === 0) {
            return (
                <div className='ecom-checkbox'>
                    <Checkbox key={item.id} value={item.id}>
                        {item.name}
                    </Checkbox>
                </div>
            );
        }

        return (
            <React.Fragment key={item.id}>
                <div className='ecom-checkbox'><Checkbox value={item.id}>{item.name}</Checkbox></div>
                <ul className="category-tree">
                    {filteredItems.map((child, i) => (
                        <li key={`${item.id}-${i}`}>{this.renderCategory(child, i)}</li>
                    ))}
                </ul>
            </React.Fragment>
        );
    }

    render() {
        const { checkedList } = this.state;
        const { items, parentId } = this.props;
        const filteredItems = items.filter(item => item.parent === parentId);

        if (!filteredItems || filteredItems.length === 0) {
            return <div>No categories available.</div>;
        }

        return (
            <Checkbox.Group style={{ display: 'block' }} value={checkedList} onChange={value => this.changeCheckBox(value)}>
                {filteredItems.map((item, index) => this.renderCategory(item, index))}
            </Checkbox.Group>
        );
    }
}

export default RenderCategoryTree;

/// Dùng cách này cũng được, nhưng không chọn được hết các danh mục
// import React from 'react';
// import { Checkbox } from 'antd';

// class RenderCategoryTree extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         };
//     }

//     changeCheckBox(value) {
//         this.props.onChangeCheckedItems([...value]);
//     }
//     render() {
//         const { items, parentId } = this.props;

//         if (!items || items.length === 0) {
//             return <div>No categories available.</div>;
//         }

//         const filteredItems = items.filter(item => item.parent === parentId);

//         return (
//             <Checkbox.Group style={{ display: 'block' }} onChange={(value) => this.changeCheckBox(value)} >
//                 <ul className='category-tree'>
//                     {filteredItems.map(item => (
//                         <li key={item.id}>
//                             <Checkbox value={item.id}>
//                                 {item.name}
//                             </Checkbox>
//                             <RenderCategoryTree
//                                 items={items}
//                                 parentId={item.id}
//                                 onChangeCheckedItems={this.props.onChangeCheckedItems}
//                             />
//                         </li>
//                     ))}
//                 </ul>
//             </Checkbox.Group>
//         );
//     }
// }

// export default RenderCategoryTree;

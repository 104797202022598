import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, TreeSelect, Row, Col, Drawer, Divider } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import IntlMessage from '../../components/IntlMessage';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import UploadImage from '../../components/UploadImage';
import ListCurrentImage from "../../components/ListCurrentImage";

import { getCategoriesActive, createCategories, updateCategories } from '../../redux/actions/ProductCategoriesAction';
import { makeTree, processSingleImageUpload } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications';

class ProductCategoriesForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            image: [],
            currentImage: []
        };
    }

    componentDidMount() {
        this.props.getCategoriesActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.image && Array.isArray(nextProps?.currentItem?.image)) {
                this.setState({ currentImage: nextProps?.currentItem?.image })
            } else {
                this.setState({ currentImage: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.image;
            if (!err) {
                try {
                    this.setState({ isLoading: true });
                    const { image, currentImage } = this.state;
                    values.image = processSingleImageUpload(image, currentImage);
                    console.log(values);
                    if (this.props.currentItem) {
                        await this.props.updateCategories(this.props.currentItem.id, values);
                        this.onClose();
                        this.props.reloadData()
                    } else {
                        await this.props.createCategories(values);
                        //this.onClose();
                        this.setState({ isResetUpload: true, image: [], currentImage: [], isLoading: false });
                        this.props.reloadData()
                        this.props.form.resetFields();
                    }
                    this.props.getCategoriesActive();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({ isResetUpload: true, image: [], currentImage: [], isLoading: false });
        this.props.onCancel();
        this.props.form.resetFields();
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Inage complete.");
    }

    getImage(data) {
        this.setState({ currentImage: data })
    }

    render() {
        const { currentImage, isResetUpload, isLoading } = this.state;
        var { visible, currentItem, categories_active_list } = this.props;

        const { getFieldDecorator } = this.props.form;
        const categoriesParentTree = makeTree(categories_active_list || [], 0);

        return (
            <Drawer
                visible={visible}
                title={currentItem ? <IntlMessage id="title.updateRecord" /> : <IntlMessage id="title.createRecord" />}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Cancel
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentItem ? "Update" : "Create"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Name">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            {
                                currentItem ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Slug">
                                            {getFieldDecorator('slug', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Field is required!',
                                                    },
                                                ],
                                                initialValue: currentItem ? currentItem.slug : null
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            <Col xs={24} md={24}>
                                <Form.Item label="Parent">
                                    {getFieldDecorator('parent', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.parent : 0
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={categoriesParentTree || []}
                                            showSearch
                                            multiple={false}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Select a category"
                                            treeDefaultExpandAll
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="SEO Title">
                                    {getFieldDecorator('seo_title', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.seo_title : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="SEO Descriptiom">
                                    {getFieldDecorator('seo_description', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.seo_description : null
                                    })(<Input.TextArea rows={4} />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Canonical Url">
                                    {getFieldDecorator('canonical_url', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.canonical_url : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Status">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Active", value: 1 },
                                                { label: "InActive", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Show on Homepage">
                                    {getFieldDecorator('show_homepage', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.show_homepage : 0
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Yes", value: 1 },
                                                { label: "No", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Image" className='flex-upload border-dashed p-2'>
                                    <ListCurrentImage currentImages={currentImage} getData={(data) => this.getImage(data)} />
                                    {getFieldDecorator('image', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={1}
                                            onChangeData={this.onChangeImage}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Description">
                                    {getFieldDecorator('description', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentItem?.description || null
                                    })(
                                        <CKEditorComponent
                                            initialHeight="400px"
                                            editorData={currentItem?.description}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories_active_list: state.ProductCategories.categories_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCategories: (data) => dispatch(createCategories(data)),
        updateCategories: (id, data) => dispatch(updateCategories(id, data)),
        getCategoriesActive: (filter) => dispatch(getCategoriesActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductCategoriesForm' })(ProductCategoriesForm));

import api from '../../utils/api';
import {
    LIST_PRODUCTS,
    GET_ONE_PRODUCTS,
    DELETE_PRODUCTS,
    CREATED_PRODUCTS,
    UPDATE_PRODUCT
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllProducts = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/products', { params: filter }).then(res => {
            dispatch({
                type: LIST_PRODUCTS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneProduct = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/products/${id}`).then(res => {
            dispatch({
                type: GET_ONE_PRODUCTS,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deleteProduct = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/products', { data: { ids: ids } }).then(res => {
            dispatch({
                type: DELETE_PRODUCTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createdProduct = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put('/products', data).then(res => {
            dispatch({
                type: CREATED_PRODUCTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateProduct = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/products/update/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_PRODUCT,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg)
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
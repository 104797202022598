import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Form, Input, Button, Row, Col, Descriptions, Tag, Divider } from 'antd';
import PageTitle from '../../components/PageTitle';
import { changePassword, updateAuthUser } from '../../redux/actions/AuthActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/profile',
        breadcrumbName: 'Profile',
    },
]

class Profile extends Component {
    state = {
        loading: false,
        visible: false,
        confirmLoading: false
    }

    formRef = React.createRef();

    handleSubmit = (values) => {
        this.setState({ loading: true });
        this.props.changePassword(values).then(() => {
            this.formRef.current.resetFields();
            this.setState({ loading: false })
        }).catch(() => {
            this.setState({ loading: false })
        })
    }

    handleCancel() {
        this.setState({ visible: false, confirmLoading: false })
    }

    render() {
        var { loading } = this.state;
        var { authUser } = this.props;

        return (
            <div>
                <PageTitle routes={routes} />
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Card
                            className="mb-4 mt-3"
                            title="ACCOUNT"
                            size='small'
                        >
                            <Row gutter={24}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Full Name">
                                            {authUser?.full_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Username">
                                            {authUser?.user_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Email">
                                            {authUser?.user_email}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Role">
                                            <Tag color="cyan">{authUser?.role_name}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Card className="mb-4"
                            size='small'
                            title="CHANGE PASSWORD"
                        >
                            <Form
                                layout="vertical"
                                onFinish={this.handleSubmit}
                                ref={this.formRef}
                            >
                                <Form.Item
                                    label="Your current password"
                                    name="old_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your current password!'
                                        }
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="New password"
                                            name="new_password"
                                            rules={[
                                                { required: true, message: 'Please enter your new password!' }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Confirm new password"
                                            name="confirm_new_password"
                                            rules={[
                                                { required: true, message: 'Please confirm your new password!' }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ textAlign: 'right' }} className='mb-0'>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Confirm
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        config: state.config,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (data) => dispatch(changePassword(data)),
        updateAuthUser: (data) => dispatch(updateAuthUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Checkbox, Alert, Button, Spin, Divider } from 'antd';
import BaseCheckboxes from '../../components/Elements/BaseCheckboxes';
import BaseSelect from '../../components/Elements/BaseSelect';
import { chunkGroup } from '../../utils/permission';
import _ from 'lodash';
import IntlMessages from '../../components/IntlMessage';
import { NotificationManager } from 'react-notifications';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
// actions
import { getAllPermissions, updateRolePermissions, getPermissionsByRole, getPermissionsByUser } from '../../redux/actions/PermissionActions';
import { getAllUsers } from '../../redux/actions/UserActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/permissions',
        breadcrumbName: 'Authorize',
    },
]

const PermissionGroup = ({ groups, permissionGroup, onCheckAll, onCheck }) => (
    <React.Fragment>
        {
            groups.map((groupChunk, index) => {
                return (
                    <Row gutter={16} style={{ height: 'max-content' }} key={index}>
                        {
                            groupChunk.map((group, index) => {
                                let groupName = group[0].route_name.split('.')[1];
                                let groupChecked = false;
                                if (permissionGroup[groupName]) {
                                    if (permissionGroup[groupName].length === group.length) groupChecked = true;
                                }

                                return (
                                    <Col lg={6} md={6} sm={24} xs={24} key={index} className="mb-4" >
                                        <Card
                                            title={<IntlMessages id={`admin.${groupName}`} />}
                                            size='small'
                                            extra={
                                                <Checkbox
                                                    onChange={(e) => onCheckAll(e, groupName, group)}
                                                    defaultChecked={groupChecked}
                                                ></Checkbox>
                                            }
                                            style={{ marginBottom: '20px', height: '100%' }}
                                        >
                                            <BaseCheckboxes
                                                options={group}
                                                optionLabel="name"
                                                optionValue="id"
                                                vertical
                                                onChange={(checkedValues) => onCheck(checkedValues, groupName)}
                                                value={permissionGroup[groupName]}
                                                intl={true}
                                            />
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            })
        }
    </React.Fragment>
)

function handlePermissions(permissions) {
    var permissionGroup = {};
    permissions.forEach(permission => {
        let groupName = permission.route_name.split('.')[1];
        if (!permissionGroup[groupName]) {
            permissionGroup[groupName] = [permission.id];
        } else {
            permissionGroup[groupName] = [...permissionGroup[groupName], permission.id];
        }
    })

    return permissionGroup;
}

class UserPermission extends Component {
    state = {
        permissionGroup: {},
        isLoading: false,
        currentRole: '',
        currentUser: '',
        roles: []
    }

    componentDidMount() {
        this.props.getAllPermissions();

    }

    onChangeRole(value) {
        this.setState({ currentRole: value, isLoading: true }, () => {
            this.props.getAllUsers({ role: value })
            this.props.getPermissionsByRole(this.state.currentRole).then(permissions => {
                var permissionGroup = handlePermissions(permissions);

                this.setState({ permissionGroup: permissionGroup, currentUser: '', isLoading: false });
            })
        })
    }

    onChangeUser(user) {
        if (user) {
            this.setState({ currentUser: user, isLoading: true }, () => {
                this.props.getPermissionsByUser(this.state.currentUser).then(permissions => {
                    var permissionGroup = handlePermissions(permissions);

                    this.setState({ permissionGroup: permissionGroup, isLoading: false });
                })
            })
        } else {
            this.props.getPermissionsByRole(this.state.currentRole).then(permissions => {
                var permissionGroup = handlePermissions(permissions);

                this.setState({ permissionGroup: permissionGroup, currentUser: '', isLoading: false });
            })
        }
    }

    onCheck = (checkedValues, groupName) => {
        this.setState({
            permissionGroup: {
                ...this.state.permissionGroup,
                [groupName]: checkedValues
            }
        })
    }

    onCheckAll = (e, groupName, group) => {
        let values = group.map(item => item.id)
        if (e.target.checked) {
            this.setState({
                permissionGroup: {
                    ...this.state.permissionGroup,
                    [groupName]: values
                }
            })
        } else {
            this.setState({
                permissionGroup: {
                    ...this.state.permissionGroup,
                    [groupName]: []
                }
            })
        }
    }

    submit() {
        if (!this.state.currentRole) {
            NotificationManager.error("You haven't selected a role!")
        } else {
            let keys = Object.keys(this.state.permissionGroup);
            let permissionArr = [];
            keys.forEach(key => {
                permissionArr = [...permissionArr, ...this.state.permissionGroup[key]];
            });

            var data = {
                role: this.state.currentRole,
                permissions: permissionArr
            }

            if (this.state.currentUser) data.user = this.state.currentUser;

            this.props.updateRolePermissions(data);
        }
    }

    render() {
        var { permissions, config } = this.props;
        var { isLoading, currentRole, permissionGroup, currentUser } = this.state;

        var roles = [];
        if (config.roles) {
            roles = config.roles.slice(1);
        }
        var users = [];
        if (currentRole) {
            users = config.users.filter(item => item.role == currentRole);
        }

        //console.log(permissions);
        const productsPermissions = ['products', 'product_categories', 'product_tags', 'attributes'];

        let productsGroup = [];
        let others = [];

        permissions.forEach(permission => {
            if (productsPermissions.indexOf(permission.resource) >= 0) {
                productsGroup.push(permission);
            } else {
                others.push(permission);
            }
        });

        productsGroup = chunkGroup(productsGroup)
        others = chunkGroup(others);

        return (
            <div className="mb-4">
                <PageTitle title="User permissions" routes={routes} />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showSearch={false}
                        showFilter={false}
                    >
                        Select permission type:&nbsp;
                        <BaseSelect
                            options={roles}
                            optionValue="id"
                            allowClear={false}
                            optionLabel="name"
                            showSearch
                            value={currentRole || null}
                            style={{ width: '250px' }}
                            placeholder="Select a role"
                            className="table-button"
                            onChange={(value) => this.onChangeRole(value)}
                        />
                        <BaseSelect
                            options={users}
                            showSearch
                            allowClear={false}
                            value={currentUser || null}
                            optionValue="user_id"
                            optionLabel="full_name"
                            style={{ width: '250px' }}
                            placeholder="All users"
                            className="table-button"
                            onChange={(value) => this.onChangeUser(value)}
                        />

                    </TableActionBar>
                </div>
                <div className="mt-4"></div>
                <Alert
                    message="Note"
                    description="Users with Super Admin role always have full access permissions."
                    type="info"
                />
                <div className="mt-4"></div>
                {
                    isLoading ? (
                        <div className="text-center">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <React.Fragment>
                            <Divider orientation="left" orientationMargin="0">Product management</Divider>
                            <PermissionGroup groups={productsGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Other permissions</Divider>
                            <PermissionGroup groups={others} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                            <Row justify="end">
                                <Button type="primary" onClick={() => this.submit()}>Confirm</Button>
                            </Row>
                        </React.Fragment>
                    )
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state.permission)
    return {
        permissions: state.permission.permissions,
        permissionsByRole: state.permission.permissionsByRole,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllPermissions: () => dispatch(getAllPermissions()),
        updateRolePermissions: (data) => dispatch(updateRolePermissions(data)),
        getPermissionsByRole: (role) => dispatch(getPermissionsByRole(role)),
        getPermissionsByUser: (user) => dispatch(getPermissionsByUser(user)),
        getAllUsers: (filter) => dispatch(getAllUsers(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPermission);
import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Modal, Image, Button, Divider, TreeSelect, Row, DatePicker, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import { Form } from "@ant-design/compatible";
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import TextTruncate from 'react-text-truncate';
import { ConvertRecordStatus, cleanObject, isValidDate, makeTree, recordStatus, stripHtml } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import { checkPermission } from '../../utils/permission';
import ProductForm from './ProductForm';

// actions
import { getAllProducts, deleteProduct, getOneProduct } from '../../redux/actions/ProductsAction';
import { getCategoriesActive } from '../../redux/actions/ProductCategoriesAction';

const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/products',
        breadcrumbName: 'All Products',
    }
];

const { RangePicker } = DatePicker;
const { confirm } = Modal;

class ProductsList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentData: null
        }
    }

    componentDidMount() {
        this.loadData();
        this.props.getCategoriesActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProducts(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllProducts(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.start_date = moment(value[0]).format('YYYY-MM-DD');
                query.end_date = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.start_date;
                delete query.end_date;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    onDelete() {
        this.setState({ isloading_delete: true })
        this.props.deleteProduct(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.loadData()
        })
    }

    openAlert(id) {
        confirm({
            title: 'Warning',
            content: 'Are you sure you want to delete this item? This action cannot be undone.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => this.props.deleteProduct([id]).then(() => {
                this.loadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenForm(isOpenForm, currentData = null) {
        this.setState({
            isOpenForm: isOpenForm,
            currentData: currentData
        });
    }

    onEdit(id) {
        this.props.getOneProduct(id).then((res) => {
            this.toggleOpenForm(true, res)
        })
    }

    render() {
        var { selectedRowKeys, isLoading, currentData, isOpenForm } = this.state;
        const { list_products, pagination, categories_active_list } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const catTree = makeTree(categories_active_list || [], 0);

        //console.log('list_products', list_products);

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                key: 'id',
                width: '50px'
            },
            {
                title: 'Actions',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    return (
                        <>
                            {
                                checkPermission('products', 'update') ? (
                                    <>
                                        <Tooltip title="Update">
                                            <EditOutlined className='primary-color item-action-btn icon-action' onClick={() => this.onEdit(record.id)} />
                                        </Tooltip>
                                        <Divider type='vertical' />
                                    </>
                                ) : null
                            }
                            {
                                checkPermission('products', 'delete') ? (
                                    <Tooltip title="Delete">
                                        <DeleteOutlined className='item-action-btn-remove item-action-btn icon-action' onClick={() => this.openAlert(record.id)} />
                                    </Tooltip>
                                ) : null
                            }
                        </>
                    )
                }
            },
            {
                title: 'Thumbnail',
                width: '100px',
                render: (text, record) => {
                    if (text.thumbnail) {
                        return (
                            <>
                                <Image
                                    width={80}
                                    src={record.thumbnail}
                                />
                            </ >
                        )
                    }
                }
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '300px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Paragraph style={{ margin: 0 }} copyable={{ tooltips: ['Copy url'], text: record.slug }}>{text}</Paragraph>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Price ($)',
                dataIndex: 'price',
                key: 'price',
                render: (text, record) => {
                    return (
                        <>
                            <div><span className='font-weight-500'>Regular: </span>{record.regular_price}</div>
                            <div><span className='font-weight-500'>Sale: </span>{record.sale_price}</div>
                        </>
                    )
                }
            },
            {
                title: 'Sale',
                dataIndex: 'total_sale',
                key: 'total_sale',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            <div><span className='font-weight-500'>Count: </span>{record.order_count}</div>
                            <div><span className='font-weight-500'>Total sale: </span>{record.total_sales}</div>
                        </>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{ConvertRecordStatus(text)}</div>
                    )
                }
            },
            {
                title: 'Categories',
                render: (text, record) => {
                    return (
                        <ul className="custom-ul">
                            {
                                record?.categories?.map(item => {
                                    return (
                                        <li>{item.name}</li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
            },
            {
                title: 'Tags',
                width: '150px',
                render: (text, record) => {
                    return (
                        <Tooltip placement="topLeft" title={record.tags_name}>
                            <TextTruncate line={3} truncateText="…" text={stripHtml(record.tags_name)} />
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Creator',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Created at',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            }
        ];

        const filters = [
            <TreeSelect
                className="w-100"
                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                treeData={catTree}
                allowClear
                showSearch
                treeNodeFilterProp='title'
                placeholder="Category"
                treeDefaultExpandAll
                defaultValue={parseInt(query.primary_category_id) || null}
                onChange={(value) => this.onChangeFilter("primary_category_id", value)}
            />,
            <BaseSelect
                options={recordStatus}
                onChange={(value) => this.onChangeFilter("status", value)}
                placeholder="Status"
                defaultValue={query.status || null}
                className="w-100"
            />,
            <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Start date', 'End date']}
                className="w-100"
                onChange={(value) => this.onChangeFilter('created_at', value)}
                defaultValue={[query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null, query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null]}
            />,
            <BaseSelect
                options={[
                    { value: 'all_product', label: 'All product' },
                    { value: 'have_sale', label: 'Have sale' },
                ]}
                onChange={(value) => this.onChangeFilter("action", value)}
                placeholder="List type"
                defaultValue={query.action || null}
                className="w-100"
            />
        ];

        return (
            <div>
                <PageTitle routes={routes} title="All Products" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('products', 'create')}
                        isShowDeleteButton={checkPermission('products', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onDelete()}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={
                            query.status ||
                                parseInt(query.primary_category_id) ||
                                query.created_at ||
                                query.action ? true : false ||
                            null}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        <Button className='table-button' loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Selecting {selectedRowKeys.length} records </Row> : ''
                    }
                </div>

                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={list_products ?? []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Total ${total} records`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <ProductForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {

    return {
        list_products: state.ProductsReducer.list_products,
        pagination: state.ProductsReducer.pagination,
        authUser: state.auth.authUser,
        categories_active_list: state.ProductCategories.categories_active_list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllProducts: filter => dispatch(getAllProducts(filter)),
        deleteProduct: (ids) => dispatch(deleteProduct(ids)),
        getCategoriesActive: (filter) => dispatch(getCategoriesActive(filter)),
        getOneProduct: (id) => dispatch(getOneProduct(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductsList" })(ProductsList));

import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, DatePicker, Image, Button, Tooltip, Divider } from 'antd';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { ConvertRecordStatus, cleanObject, isValidDate, recordStatus, replaceHeadingsWithDivs, stripHtml } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import PostsForm from './PostsForm';

// actions
import { getListPosts, getOnePosts, removePosts, updatePosts } from '../../redux/actions/PostsAction';
import HtmlParser from 'react-html-parser';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/posts',
        breadcrumbName: 'Posts List',
    }
];

const { confirm } = Modal;

class PostsList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            isOpenDetail: false,
            current_id: null,
            isOpenForm: false,
            currentData: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListPosts({ ...query }).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove() {
        this.props.removePosts(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.loadData()
        })
    }

    openAlert(id) {
        confirm({
            title: 'Warning',
            content: 'Are you sure you want to delete this item? This action cannot be undone.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => this.props.removePosts([id]).then(() => {
                this.loadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListPosts({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            isOpenForm: false
        })
    }

    toggleOpenForm(isOpenForm, currentData = null) {
        this.setState({
            isOpenForm: isOpenForm,
            currentData: currentData
        });
    }

    onEdit(id) {
        this.props.getOnePosts(id).then((res) => {
            this.toggleOpenForm(true, res)
        })
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenForm,
            currentData,
        } = this.state;
        const { posts, pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '40px'
            },
            {
                title: 'Actions',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    return (
                        <>
                            {
                                checkPermission('posts', 'update') ? (
                                    <>
                                        <Tooltip title="Update">
                                            <EditOutlined className='primary-color item-action-btn icon-action' onClick={() => this.onEdit(record.id)} />
                                        </Tooltip>
                                        <Divider type='vertical' />
                                    </>
                                ) : null
                            }
                            {
                                checkPermission('posts', 'delete') ? (
                                    <Tooltip title="Delete">
                                        <DeleteOutlined className='item-action-btn-remove item-action-btn icon-action' onClick={() => this.openAlert(record.id)} />
                                    </Tooltip>
                                ) : null
                            }
                        </>
                    )
                }
            },
            {
                title: 'Image',
                width: '50px',
                render: (text, record) => {
                    if (text.image) {
                        return (
                            <>
                                <Image
                                    width={50}
                                    src={record.image}
                                />
                            </ >
                        )
                    }
                }
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                width: "300px",
                render: (text, record) => {
                    return (
                        <Tooltip placement='right' title={HtmlParser(replaceHeadingsWithDivs(record.title))}>
                            <TextTruncate text={stripHtml(record.title)} line={1} truncateText="..." />
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Categories',
                dataIndex: 'categories',
                key: 'stacategoriestus',
                width: '200px'
            },
            {
                title: 'Tags',
                width: '150px',
                render: (text, record) => {
                    return (
                        <Tooltip placement="topLeft" title={record.tags_name}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(record.tags_name)} />
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                render: (text, record) => (
                    <div>{ConvertRecordStatus(text)}</div>
                )
            },
            {
                title: 'Creator',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Created at',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            }
        ];

        var filters = [
            <BaseSelect
                options={recordStatus}
                onChange={(value) => this.onChangeFilter("status", value)}
                placeholder="Status"
                defaultValue={query.status ? parseInt(query.status) : null}
                className="w-100"
            />,
            <DatePicker
                format="DD/MM/YYYY"
                className="w-100"
                placeholder="Start Date"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                className="w-100"
                placeholder="End Date"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Posts List" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('posts', 'create')}
                        isShowDeleteButton={checkPermission('posts', 'remove')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        onAdd={() => this.toggleOpenForm(true)}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={query.status || parseInt(query.status)}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        <Button className='table-button' icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Selected {selectedRowKeys.length} items </span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={posts || []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Total ${total} items`,
                        defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <PostsForm
                    visible={isOpenForm}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenForm(false)}
                    reloadData={() => this.loadData()}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        posts: state.PostsReducer.posts,
        pagination: state.PostsReducer.pagination,
        authUser: state.auth.authUser,
        users: state.config.users,
        admins: state.config.admins
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListPosts: filter => dispatch(getListPosts(filter)),
        removePosts: ids => dispatch(removePosts(ids)),
        updatePosts: (id, data) => dispatch(updatePosts(id, data)),
        getOnePosts: (id) => dispatch(getOnePosts(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostsList);

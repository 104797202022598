import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, TreeSelect, Row, Col, Drawer, Divider } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import IntlMessage from '../../components/IntlMessage';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
// actions
import { getCategoriesActive, createCategories, updateCategories } from '../../redux/actions/PostCategoriesAction';
import { _newLine, makeTree } from '../../utils/helpers';

class PostCategoriesForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    componentDidMount() {
        this.props.getCategoriesActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateCategories(this.props.currentItem.id, values);
                    this.onClose();
                } else {
                    await this.props.createCategories(values);
                    //this.onClose();
                    this.props.reloadData()
                    this.props.form.resetFields();
                }
                this.props.getCategoriesActive();
            }
        });
    }

    onClose() {
        this.props.reloadData()
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        const { } = this.state;
        var { visible, currentItem, categories_active_list } = this.props;

        const { getFieldDecorator } = this.props.form;
        const categoriesParentTree = makeTree(categories_active_list || [], 0);

        return (
            <Drawer
                visible={visible}
                title={currentItem ? <IntlMessage id="title.updateRecord" /> : <IntlMessage id="title.createRecord" />}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Cancel
                        </Button>
                        <Divider type='vertical' />
                        <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentItem ? "Update" : "Create"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Name">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            {
                                currentItem ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Slug">
                                            {getFieldDecorator('slug', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Field is required!',
                                                    },
                                                ],
                                                initialValue: currentItem ? currentItem.slug : null
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            <Col xs={24} md={24}>
                                <Form.Item label="Parent">
                                    {getFieldDecorator('parent', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.parent : 0
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={categoriesParentTree || []}
                                            showSearch
                                            multiple={false}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Select a category"
                                            treeDefaultExpandAll
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Description">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Field is required!",
                                    },
                                ],
                                initialValue: currentItem?.description || null
                            })(
                                <CKEditorComponent
                                    initialHeight="400px"
                                    editorData={currentItem?.description}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Note">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Field is required!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : null
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                        <Form.Item label="Status">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Field is required!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Active", value: 1 },
                                        { label: "InActive", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories_active_list: state.PostCategoriesReducer.categories_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCategories: (data) => dispatch(createCategories(data)),
        updateCategories: (id, data) => dispatch(updateCategories(id, data)),
        getCategoriesActive: (filter) => dispatch(getCategoriesActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PostCategoriesForm' })(PostCategoriesForm));

import api from '../../utils/api';
import { NotificationManager } from 'react-notifications';

import {
    GET_LIST_ATTRIBUTE_GROUPS,
    GET_SPECIFIC_ATTRIBUTE_GROUPS,
    UPDATE_SPECIFIC_ATTRIBUTE_GROUPS,
    CREATE_NEW_ATTRIBUTE_GROUPS,
    REMOVE_ATTRIBUTE_GROUPS
} from './types';

export const getListAttributeGroups = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/attribute-groups', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_ATTRIBUTE_GROUPS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificAttributeGroups = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/attribute-groups/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_ATTRIBUTE_GROUPS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createAttributeGroups = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/attribute-groups', data).then(res => {
            dispatch({ type: CREATE_NEW_ATTRIBUTE_GROUPS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateAttributeGroups = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/attribute-groups/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_ATTRIBUTE_GROUPS, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeAttributeGroups = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/attribute-groups`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_ATTRIBUTE_GROUPS, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

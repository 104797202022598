import appLocaleData from 'react-intl/locale-data/en';
import enMessages from '../locales/en_US';
import enNotification from '../notification/en_US';

const EnLang = {
    messages: {
        ...enMessages,
        ...enNotification
    },
    locale: 'en-US',
    data: appLocaleData
};
export default EnLang;
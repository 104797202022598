import api from '../../utils/api';
import {
    GET_LIST_POSTS,
    GET_ONE_POSTS,
    CREATE_POSTS,
    UPDATE_POSTS,
    REMOVE_POSTS
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListPosts = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/posts', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_POSTS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOnePosts = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/posts/${id}`).then(res => {
            dispatch({
                type: GET_ONE_POSTS,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createNewPost = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/posts', data).then(res => {
            dispatch({
                type: CREATE_POSTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updatePosts = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/posts/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_POSTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg)
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removePosts = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/posts', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_POSTS,
                payload: ids
            });
            if (res.data.data == 0) {
                NotificationManager.error(res.data.msg);
            } else {
                NotificationManager.success(res.data.msg);
            }
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
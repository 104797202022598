import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, Drawer, Divider, Tag, AutoComplete, TreeSelect } from "antd";
import { isMobile } from 'react-device-detect';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'
import UploadImage from '../../components/UploadImage';
import ListCurrentImage from "../../components/ListCurrentImage";
import { updatePosts, createNewPost } from "../../redux/actions/PostsAction";
import BaseRadioList from "../../components/Elements/BaseRadios";
import { convertToSlug, makeTree, processSingleImageUpload, recordStatus } from "../../utils/helpers";
import { NotificationManager } from "react-notifications";
import { getAllTags } from '../../redux/actions/TagsAction';
import { getCategoriesActive } from '../../redux/actions/PostCategoriesAction';
import appConfing from '../../config';

class PostsForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            editorData: '',
            image: [],
            currentImage: [],
            isResetUpload: false,
            inputTagValue: "",
            filteredTags: [],
            selectedTags: [],
            tempTags: [],
        };
    }

    componentDidMount() {
        this.props.getCategoriesActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            if (nextProps?.currentData?.image && Array.isArray(nextProps?.currentData?.image)) {
                this.setState({ currentImage: nextProps?.currentData?.image })
            } else {
                this.setState({ currentImage: [] })
            }
            if (nextProps?.currentData?.tags_name && Array.isArray(nextProps?.currentData?.tags_name)) {
                this.setState({ selectedTags: nextProps?.currentData?.tags_name })
            } else {
                this.setState({ selectedTags: [] })
            }
        }
    }

    submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                delete values.image;
                delete values.tags
                this.setState({ isLoading: true });
                try {
                    const { image, currentImage, selectedTags } = this.state;
                    values.image = processSingleImageUpload(image, currentImage);
                    values.tags = selectedTags;

                    console.log(values)
                    if (this.props.currentData) {
                        await this.props.updatePosts(this.props.currentData.id, values);
                    } else {
                        await this.props.createNewPost(values);
                    }

                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            image: [],
            inputTagValue: "",
            filteredTags: [],
            selectedTags: [],
            tempTags: [],
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleEditorChange = (data) => {
        //console.log(data)
        this.setState({ editorData: data });
    };

    onChangeImage = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Image complete.");
    }

    getImage(data) {
        this.setState({ currentImage: data })
    }

    handleInputTagChange = async (inputValue) => {
        this.setState({ inputTagValue: inputValue });
        // Lấy từ cuối cùng sau dấu phẩy
        const keywords = inputValue.split(',');
        const lastKeyword = keywords[keywords.length - 1].trim();
        this.props.getAllTags({ keyword: lastKeyword }).then(res => {
            const { tempTags } = this.state;
            const filteredTags = res.data.data.filter(tag => !tempTags.includes(tag));
            this.setState({
                filteredTags
            });
        });
    };

    handleTagSelect = (value) => {
        const { tempTags } = this.state;
        const newTag = value.trim();

        if (newTag && !tempTags.includes(newTag)) {
            const updatedTags = [...tempTags, newTag];
            const updatedInputValue = updatedTags.join(', ') + ', ';
            this.setState({
                tempTags: updatedTags,
                inputTagValue: updatedInputValue,
                filteredTags: []
            });
        } else {
            const updatedInputValue = tempTags.join(', ') + ', ';
            this.setState({
                inputTagValue: updatedInputValue
            });
        }
    };

    handleAddTag = () => {
        const { inputTagValue, selectedTags } = this.state;
        const newTags = inputTagValue.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
        const updatedSelectedTags = [...selectedTags, ...newTags];
        // Lọc trùng lặp trong danh sách selectedTags
        const uniqueSelectedTags = [...new Set(updatedSelectedTags)];
        this.setState({
            selectedTags: uniqueSelectedTags,
            inputTagValue: '',
            filteredTags: [],
            tempTags: []
        });
    };

    renderSelectedTags = () => {
        const { selectedTags } = this.state;
        return selectedTags.map((tag, index) => (
            <Tag
                className="edit-tag mb-1"
                key={tag}
                closable={true}
                color="cyan"
                onClose={() => this.handleClose(tag)}
            >
                {tag}
            </Tag>
        ));
    }

    handleClose = (removedTag) => {
        const { selectedTags } = this.state;
        const updatedTags = selectedTags.filter(tag => tag !== removedTag);
        this.setState({
            selectedTags: updatedTags
        });
    }

    createSlug(e) {
        var slug = convertToSlug(e.target.value);
        this.props.form.setFieldsValue({ slug: slug });
    }

    render() {
        const { visible, currentData, categories_active_list } = this.props;
        const { isLoading, currentImage, isResetUpload, inputTagValue, filteredTags } = this.state;
        const { getFieldDecorator } = this.props.form;
        const catTree = makeTree(categories_active_list || [], 0);
        //console.log(currentData)

        return (
            <Drawer
                visible={visible}
                title={currentData ? `Update Post` : 'Add New Post'}
                width={isMobile ? '100%' : '60%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Cancel
                        </Button>
                        <Divider type='vertical' />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Title">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData?.title : null
                                    })(<Input
                                        onKeyPress={(e) => this.createSlug(e)}
                                        onBlur={(e) => this.createSlug(e)}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Permalink">
                                    {getFieldDecorator("slug", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.slug : null
                                    })(
                                        <Input addonBefore={`${appConfing.BASE_URL}/blog/`} addonAfter="/" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Content">
                                    {getFieldDecorator('content', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentData?.content || null
                                    })(
                                        <CKEditorComponent
                                            onChange={this.handleEditorChange}
                                            initialHeight="300px"
                                            editorData={currentData?.content}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label='Categories'>
                                    {getFieldDecorator("category_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.category_id : null
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={catTree}
                                            showSearch={true}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Select one categories"
                                            treeDefaultExpandAll
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label='Status'>
                                    {getFieldDecorator("status", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Field is required!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.status : null
                                    })(
                                        <BaseRadioList
                                            options={recordStatus}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Select tags">
                                    {getFieldDecorator('tags', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Please select tags!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.tags : inputTagValue
                                    })(
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <AutoComplete
                                                value={inputTagValue}
                                                onChange={this.handleInputTagChange}
                                                onSelect={this.handleTagSelect}
                                                style={{ flex: 1, marginRight: '8px' }}
                                                options={filteredTags.map(tag => ({ value: tag.name }))}
                                                placeholder="Enter tags"
                                            >
                                                <Input />
                                            </AutoComplete>
                                            <Button
                                                type="dashed"
                                                style={{ width: '74px' }}
                                                onClick={this.handleAddTag}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    )}

                                    <div style={{ margin: "10px 0 0 0" }}>
                                        {this.renderSelectedTags()}
                                    </div>
                                    <Divider />
                                </Form.Item>

                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Featured image" className='flex-upload border-dashed p-2'>
                                    <ListCurrentImage currentImages={currentImage} getData={(data) => this.getImage(data)} />
                                    {getFieldDecorator('image', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={1}
                                            onChangeData={this.onChangeImage}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} className="mt-3">
                                <Form.Item label="SEO Title">
                                    {getFieldDecorator('seo_title', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.seo_title : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="SEO Descriptiom">
                                    {getFieldDecorator('seo_description', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.seo_description : null
                                    })(<Input.TextArea rows={4} />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Canonical Url">
                                    {getFieldDecorator('canonical_url', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.canonical_url : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
        categories_active_list: state.PostCategoriesReducer.categories_active_list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePosts: (id, data) => dispatch(updatePosts(id, data)),
        createNewPost: (data) => dispatch(createNewPost(data)),
        getAllTags: (filter) => dispatch(getAllTags(filter)),
        getCategoriesActive: (filter) => dispatch(getCategoriesActive(filter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "PostsForm" })(PostsForm));

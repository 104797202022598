import React, { Component } from 'react';
import { connect } from 'react-redux';

class Home extends Component {
    state = {

    }

    render() {
        return (
            <h1 style={{ textAlign: 'center' }} className='mt-30'>customyst.com</h1>
        )
    }

}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { DeleteOutlined, DownOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, Button, Divider, Popover, Tooltip } from 'antd';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, ConvertBaseStatus, isValidDate } from '../../utils/helpers';
import AttributeGroupForm from './AttributeGroupForm';
import { getListAttributeGroups, getSpecificAttributeGroups, removeAttributeGroups, createAttributeGroups } from '../../redux/actions/AttributeGroupAction';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/attribute-groups',
        breadcrumbName: 'Attribute groups',
    }
]

class AttributeGroupList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentData: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListAttributeGroups({ ...query }).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove() {
        this.props.removeAttributeGroups(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.loadData()
        })
    }

    openAlert(id) {
        confirm({
            title: 'Warning',
            content: 'Are you sure you want to delete this item? This action cannot be undone.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => this.props.removeAttributeGroups(id).then(() => {
                this.loadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAttributeGroups({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            isOpenForm: false
        })
    }

    toggleOpenForm(isOpenForm, currentData = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: currentData });
    }

    onEdit(id) {
        this.props.getSpecificAttributeGroups(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenForm,
            currentData
        } = this.state;
        const { attribute_group_list, pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '40px'
            },
            {
                title: 'Actions',
                width: '80px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('attribute_groups', 'update') ? (
                                <Tooltip title="Edit">
                                    <EditOutlined className='color-primary item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </Tooltip>
                            ) : null
                        }
                        {
                            checkPermission('attribute_groups', 'remove') ? (
                                <Tooltip title="Delete">
                                    <DeleteOutlined className='item-action-btn-remove' onClick={() => this.openAlert([record.id])} />
                                </Tooltip>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Attributes',
                dataIndex: 'attributes',
                key: 'attributes',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {item.name}
                                </div>
                            )
                        })
                        let first_item = text[0]?.name;
                        return (
                            <div className='item-action-btn'>
                                <Popover placement="right" content={content}>
                                    {first_item}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                sorter: true,
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{ConvertBaseStatus(text)}</div>
                    )
                }
            },
            {
                title: 'Creator',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '200px'
            },
            {
                title: 'Created at',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <>{isValidDate(text)}</>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Attribute groups" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('attribute_groups', 'create')}
                        isShowDeleteButton={checkPermission('attribute_groups', 'remove')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        onAdd={() => this.toggleOpenForm(true)}
                        showSearch={true}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <Button className='table-button' icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Selected {selectedRowKeys.length} items </span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={attribute_group_list || []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Total ${total} items`,
                        defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <AttributeGroupForm
                    visible={isOpenForm}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenForm(false)}
                    reloadData={() => this.loadData()}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        attribute_group_list: state.AttributeGroupReducer.attribute_group_list,
        pagination: state.AttributeGroupReducer.pagination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAttributeGroups: filter => dispatch(getListAttributeGroups(filter)),
        removeAttributeGroups: ids => dispatch(removeAttributeGroups(ids)),
        getSpecificAttributeGroups: (id) => dispatch(getSpecificAttributeGroups(id)),
        createAttributeGroups: (data) => dispatch(createAttributeGroups(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AttributeGroupList);

import {
    GET_MENUS
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    menus: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MENUS: {
            return {
                ...state,
                menus: action.payload,
            }
        }
        default: return { ...state };
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Tag, Button, Row, Col } from 'antd'
import { EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import UserForm from './UserForm';
import { downloadFromLink, isValidDate } from '../../utils/helpers';
import NumberFormat from 'react-number-format';

class UserDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentItem: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }
    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem } = this.props;
        var diffDate = currentItem?.expire ? moment(currentItem?.expire).diff(moment().startOf('day'), 'days') : 0;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`User Details: ${currentItem?.full_name}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                    extra={
                        checkPermission('user', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Edit</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Full Name">
                                            {currentItem?.full_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Username">
                                            {currentItem?.user_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="E-mail">
                                            {currentItem?.user_email}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Role">
                                            {currentItem?.role_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Status">
                                            {
                                                currentItem && currentItem?.user_active == 1 ? <><CheckCircleTwoTone twoToneColor="#52c41a" /> Active</> : null
                                            }
                                            {
                                                currentItem && currentItem?.user_active == 0 ? <><CloseCircleTwoTone twoToneColor="#e74c3c" /> Inactive</> : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                {
                                    currentItem?.role_code != 'ADMIN' ? (
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="CMS Expiry Date">
                                                    {isValidDate(currentItem?.expire, false)}
                                                    <Divider type='vertical' />
                                                    {
                                                        diffDate ? (
                                                            <span>
                                                                {
                                                                    diffDate > 0 ? <Tag color="#f50">Remains {diffDate} days</Tag> : <Tag color="red">Expired {-1 * diffDate} days ago</Tag>
                                                                }
                                                            </span>
                                                        ) : (
                                                            <Tag color="#f50">Expired today</Tag>
                                                        )
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    ) : null
                                }
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Created At">
                                            {isValidDate(currentItem?.created_at, true)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Note">
                                            {currentItem ? ReactHtmlParser(currentItem?.note) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </Drawer>

                <UserForm
                    visible={isOpenEditForm}
                    user={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'UserDetail' })(UserDetail));

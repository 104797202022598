import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Button, Input, Checkbox, Card, Row } from 'antd';
// actions
import { login } from '../../redux/actions/AuthActions';
import { getConfig } from '../../redux/actions/ConfigActions';

class Login extends Component {
    onFinish = async (values) => {
        //console.log('Success:', values);
        await this.props.login(values);
        await this.props.getConfig();
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        var { authUser } = this.props;

        if (authUser) return <Redirect to='/' />;

        return (
            <Row justify='center'>
                <Card className="login-card">
                    <div className="text-center">
                        <img src={require('../../assets/img/logo.png')} alt='' className="login-logo" />
                    </div>
                    <Form
                        className="mt-4"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            name="user_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your username!',
                                },
                            ]}
                        >
                            <Input placeholder="Username" />
                        </Form.Item>

                        <Form.Item
                            name="user_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>

                        <Row justify="space-between">
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <Link to='/reset-password'>Forgot password?</Link>
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="w-100">
                                Log In
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (data) => dispatch(login(data)),
        getConfig: () => dispatch(getConfig())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

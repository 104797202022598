import api from '../../utils/api';
import {
    LIST_TAGS,
    CREATE_TAGS,
    DELETE_TAGS,
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllTags = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/tags', { params: filter }).then(res => {
            dispatch({
                type: LIST_TAGS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createTags = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put('/tags', data).then(res => {
            dispatch({
                type: CREATE_TAGS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deleteTags = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/tags', { data: { ids: ids } }).then(res => {
            dispatch({
                type: DELETE_TAGS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
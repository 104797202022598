module.exports = {
    "admin.user.list": "View user list",
    "admin.user.create": "Create new user",
    "admin.user.detail": "View user detail",
    "admin.user.update": "Update user information",
    "admin.user.remove": "Remove user",

    "admin.permissions.list": "View permission list",
    "admin.permissions.update": "Assign permission",
    "admin.permissions.get_by_role": "Get permissions by role",

    "admin.config.update": "Update system email configuration",

    "admin.products.list": "Product list",
    "admin.products.create": "Create new",
    "admin.products.remove": "Remove",
    "admin.products.detail": "Detail",
    "admin.products.update": "Update",

    "admin.product_categories.list": "Product categories",

    "admin.product_tags.list": "Tag list",
    "admin.product_tags.create": "Create new",
    "admin.product_tags.remove": "Remove",

    "admin.posts.list": "View list",
    "admin.posts.create": "Create new",
    "admin.posts.update": "Update",
    "admin.posts.remove": "Remove",

    "admin.role": "Role management",
    "admin.user": "User",
    "admin.config": "General settings",
    "admin.products": "Product management",
    "admin.product_categories": "Product category management",
    "admin.product_tags": "Product tag management",
    "admin.posts": "Post management"
};

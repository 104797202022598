import {
    LIST_TAGS
} from '../actions/types';

const INIT_STATE = {
    list_tags: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 30
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_TAGS:
            return {
                ...state,
                list_tags: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };

        default:
            return { ...state }
    }
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Avatar, Tag } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import { cleanObject, stripHtml, getFirstLetterOfWords, isValidDate } from '../../utils/helpers';
import { checkPermission } from '../../utils/permission';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
// actions
import { getAllUsers, getUser, removeUsers } from '../../redux/actions/UserActions';
import UserForm from './UserForm';
import UserDetail from './UserDetail';
import BaseSelect from '../../components/Elements/BaseSelect';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/users',
        breadcrumbName: 'User List',
    },
]

class UserList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenUserForm: false,
        currentUser: null,
        isOpenDetail: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllUsers(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllUsers(query).then(() => {
                this.setState({
                    isLoading: false
                })
            });
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query.page = 1;
        query = cleanObject(query); // remove empty fields

        this.props.history.push({
            pathname: '/users',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));

        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order == 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/users',
            search: qs.stringify(query)
        });

    }

    toggleOpenForm(isOpenUserForm, user = null) {
        this.setState({ isOpenUserForm: isOpenUserForm, currentUser: user });
    }

    onEdit(id) {
        this.props.getUser(id).then(user => {
            this.toggleOpenForm(true, user);
        })
    }

    onRemove() {
        this.props.removeUsers(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });

            Modal.destroyAll();
        })
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getUser(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentUser: data });
    }

    render() {
        var { users, pagination, config } = this.props;
        var { isLoading, selectedRowKeys, isOpenUserForm, currentUser, isOpenDetail, current_id } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        // query string
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: true,
                width: '70px'
            },
            {
                title: 'Full Name',
                dataIndex: 'full_name',
                key: 'full_name',
                sorter: true,
                render: (text, record) => {
                    if (record.full_name) {
                        return (
                            <>
                                {record.avatar ? (
                                    <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                ) : (
                                    <>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    </>
                                )}
                                &nbsp;<span className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>{record.full_name}</span>
                            </>
                        )
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Username',
                dataIndex: 'user_name',
                key: 'user_name',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Tag>{text}</Tag>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'user_email',
                key: 'user_email',
                sorter: true
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Role',
                dataIndex: 'role_name',
                key: 'role_name',
                render: (text, record) => {
                    return (
                        <Tag>{text}</Tag>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'user_active',
                key: 'user_active',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Expiration Date',
                dataIndex: 'expire',
                key: 'expire',
                render: (text, record) => {
                    if (record.role !== 1) {
                        return (
                            <div>{isValidDate(text, false)}</div>
                        )
                    }
                }
            },
            {
                title: '#',
                dataIndex: 'expire',
                key: 'expire',
                render: (text, record) => {
                    if (record.role !== 1) {
                        var diffDate = record.expire ? moment(record.expire).diff(moment().startOf('day'), 'days') : 0;
                        return (
                            <div>
                                {
                                    diffDate ? (
                                        <div>
                                            {
                                                diffDate > 0 ? <Tag color="#f50">Remain {diffDate} days</Tag> : <Tag color="red">Expired {-1 * diffDate} days ago</Tag>
                                            }
                                        </div>
                                    ) : (
                                        <Tag color="#f50">Expired today</Tag>
                                    )
                                }
                            </div>
                        )
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle
                    title="User List"
                    routes={routes}
                />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('user', 'create')}
                        isShowDeleteButton={checkPermission('user', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        activeFilter={query.role || query.user_active}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <BaseSelect
                            style={{ width: '150px' }}
                            onChange={(value) => this.onChangeFilter("role", value)}
                            options={config.roles}
                            placeholder="Role"
                            optionValue="id"
                            optionLabel="name"
                            className="table-button"
                            defaultValue={query.role ? parseInt(query.role) : null}
                        />
                        <BaseSelect
                            placeholder="Status"
                            style={{ width: '150px' }}
                            onChange={(value) => this.onChangeFilter("user_active", value)}
                            className="table-button"
                            options={[
                                { label: "Active", value: '1' },
                                { label: "Inactive", value: '0' },
                            ]}
                            defaultValue={query.user_active || null}
                        />
                        {
                            hasSelected ? <span>Selected {selectedRowKeys.length} records</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    //bordered={true}
                    size='small'
                    dataSource={users}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Total ${total} records`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <UserForm
                    visible={isOpenUserForm}
                    user={currentUser}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <UserDetail
                    visible={isOpenDetail}
                    currentItem={currentUser}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {
        users: state.user.users,
        pagination: state.user.pagination,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllUsers: (filter) => dispatch(getAllUsers(filter)),
        getUser: (id) => dispatch(getUser(id)),
        removeUsers: (ids) => dispatch(removeUsers(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

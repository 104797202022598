// Ví dụ hiển thị thông báo trong component:
//import IntlMessage from '../../components/IntlMessage';
//<IntlMessages id="dynamic.count" values={{ count: 100000000 }} />
//<IntlMessages id="warnings.deletion" />

module.exports = {
    "errors.network": "An error occurred while connecting to the network.",
    "errors.validation": "Please fill out all required fields correctly.",
    "errors.authentication": "Authentication failed. Please check your credentials.",
    "warnings.connection": "The connection may not be secure. Proceed with caution.",
    "warnings.deletion": "Are you sure you want to delete this item? This action cannot be undone.",
    "instructions.resetPassword": "To reset your password, please follow the instructions sent to your email.",
    "successes.login": "You have successfully logged in.",
    "successes.registration": "Registration successful. Welcome aboard!",
    "successes.successAdd": "Successfully added new record!",
    "successes.successUpdate": "Record updated successfully!",
    "successes.successDelete": "Record deleted successfully!",
    "information.featureExplanation": "This feature allows you to...",
    "status.savingChanges": "Saving changes...",
    "status.loadingData": "Loading data...",
    "dynamic.userGreet": "Hello, {username}!",
    "dynamic.count": "{count}",
    "title.createRecord": "Create new record",
    "title.updateRecord": "Edit record"
}
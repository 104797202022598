import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { CopyOutlined, DeleteOutlined, DownOutlined, EditOutlined, ReloadOutlined, DragOutlined } from '@ant-design/icons';
import { Table, Modal, Button, Divider, Popover, Tooltip } from 'antd';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, ConvertBaseStatus, ConvertdisplayType, isValidDate } from '../../utils/helpers';
import ProductAttributesForm from './AttributesForm';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { getListAttributes, getSpecificAttributes, removeAttributes, createAttributes, updateSortOrder } from '../../redux/actions/AttributesAction';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Home',
    },
    {
        path: '/attributes',
        breadcrumbName: 'Attributes',
    }
];

// Drag handle component
const DragHandle = SortableHandle(() => (
    <DragOutlined className='item-action-btn-drag' onMouseDown={(e) => e.preventDefault()} />
));
// Function to move an item in an array
const moveItemInArray = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [removed] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, removed);
    return newArray;
};

// Sortable item component
const SortableItem = SortableElement((props) => <tr {...props} />);

// Sortable body container
const SortableBody = SortableContainer((props) => <tbody {...props} />);

class AttributesList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentData: null,
            sortedAttributes: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListAttributes({ ...query }).then(() => {
                this.setState({ isLoading: false });
            });
        }
        if (nextProps.attributes_list !== this.props.attributes_list) {
            // Lưu attributes_list vào state
            this.setState({ sortedAttributes: nextProps.attributes_list });
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove() {
        this.props.removeAttributes(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.loadData()
        })
    }

    openAlert(id) {
        confirm({
            title: 'Warning',
            content: 'Are you sure you want to delete this item? This action cannot be undone.',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: () => this.props.removeAttributes(id).then(() => {
                this.loadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAttributes({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            isOpenForm: false
        })
    }

    toggleOpenForm(isOpenForm, currentData = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: currentData });
    }

    onEdit(id) {
        this.props.getSpecificAttributes(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.classList.remove('noselect');

        if (oldIndex !== newIndex) {
            const newData = moveItemInArray(this.state.sortedAttributes.slice(), oldIndex, newIndex);

            // Map lại dữ liệu sau khi sắp xếp
            const updatedData = newData.map((item, index) => ({
                ...item,
                sort_order: index + 1, // Cập nhật lại thứ tự sau khi sắp xếp
            }));

            // Cập nhật state với mảng đã sắp xếp
            this.setState({
                sortedAttributes: updatedData,
            });

            this.props.updateSortOrder({ data: updatedData });

            console.log('Sorted items: ', updatedData);
        }
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenForm,
            currentData,
            sortedAttributes
        } = this.state;
        const { pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const onSortStart = () => {
            console.log('Sorting started...');
            // Add the noselect class to prevent text selection during drag
            document.body.classList.add('noselect');
        };

        // Draggable container component
        const DraggableContainer = (props) => (
            <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortStart={onSortStart}
                onSortEnd={this.onSortEnd}
                {...props}
            />
        );

        // Draggable body row component
        const DraggableBodyRow = ({ className, style, ...restProps }) => {
            const index = sortedAttributes.findIndex((x) => x.id === restProps['data-row-key']);
            return <SortableItem index={index} {...restProps} />;
        };


        const columns = [
            {
                title: 'Sort',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '40px',
                className: 'drag-visible',
            },
            {
                title: 'Actions',
                width: '100px',
                align: 'center',
                className: 'drag-visible',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('attributes', 'update') ? (
                                <Tooltip title="Edit">
                                    <EditOutlined className='color-primary item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </Tooltip>
                            ) : null
                        }
                        {
                            checkPermission('attributes', 'duplicate') ? (
                                <Tooltip title="Clone">
                                    <CopyOutlined className='item-action-btn' onClick={() => this.props.createAttributes({ clone_id: record.id }).then(res => {
                                        this.loadData();
                                    })} />
                                    <Divider type='vertical' />
                                </Tooltip>
                            ) : null
                        }
                        {
                            checkPermission('attributes', 'remove') ? (
                                <Tooltip title="Delete">
                                    <DeleteOutlined className='item-action-btn-remove' onClick={() => this.openAlert([record.id])} />
                                </Tooltip>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                className: 'drag-visible',
            },
            {
                title: 'Label',
                dataIndex: 'label',
                key: 'label',
                className: 'drag-visible',
            },
            {
                title: 'Values',
                dataIndex: 'values',
                key: 'values',
                className: 'drag-visible',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {item.label}
                                    {record.display_type === 'circle' ? ` - ${item.value}` : ''}
                                </div>
                            )
                        })
                        let first_item = text[0]?.label;
                        return (
                            <div className='item-action-btn'>
                                <Popover placement="right" content={content}>
                                    {first_item}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Groups',
                dataIndex: 'groups',
                key: 'groups',
                className: 'drag-visible',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {item.name}
                                </div>
                            )
                        })
                        let first_item = text[0]?.name;
                        return (
                            <div className='item-action-btn'>
                                <Popover placement="right" content={content}>
                                    {first_item}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                sorter: true,
                width: '100px',
                className: 'drag-visible',
                render: (text, record) => {
                    return (
                        <div>{ConvertBaseStatus(text)}</div>
                    )
                }
            },
            {
                title: 'Display type',
                dataIndex: 'display_type',
                key: 'display_type',
                align: 'center',
                width: '100px',
                className: 'drag-visible',
                render: (text, record) => {
                    return (
                        <div>{ConvertdisplayType(text)}</div>
                    )
                }
            },
            {
                title: 'Creator',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '200px',
                className: 'drag-visible',
            },
            {
                title: 'Created at',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                className: 'drag-visible',
                render: (text, record) => {
                    return (
                        <>{isValidDate(text)}</>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Attributes" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('attributes', 'create')}
                        isShowDeleteButton={checkPermission('attributes', 'remove')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        onAdd={() => this.toggleOpenForm(true)}
                        showSearch={true}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <Button className='table-button' icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Selected {selectedRowKeys.length} items </span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={sortedAttributes || []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Total ${total} items`,
                        defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />

                <ProductAttributesForm
                    visible={isOpenForm}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenForm(false)}
                    reloadData={() => this.loadData()}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        attributes_list: state.AttributesReducer.attributes_list,
        pagination: state.AttributesReducer.pagination
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAttributes: filter => dispatch(getListAttributes(filter)),
        removeAttributes: ids => dispatch(removeAttributes(ids)),
        getSpecificAttributes: (id) => dispatch(getSpecificAttributes(id)),
        createAttributes: (data) => dispatch(createAttributes(data)),
        updateSortOrder: (data) => dispatch(updateSortOrder(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AttributesList);

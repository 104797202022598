import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input } from 'antd';
import { isMobile } from 'react-device-detect';
// actions
import { createNewRole, updateSpecificRole } from '../../redux/actions/RoleActions';

class RoleForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateSpecificRole(this.props.currentItem.id, values);
                    this.props.reloadData();
                    this.onClose();
                } else {
                    await this.props.createNewRole(values);
                    //this.onClose();
                    this.props.form.resetFields();
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        var { visible, currentItem } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                visible={visible}
                title={currentItem ? "Edit role" : "Add new role"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentItem ? "Update" : "Add new"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Name">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Field is required',
                                    },
                                ],
                                initialValue: currentItem ? currentItem.name : ""
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNewRole: (data) => dispatch(createNewRole(data)),
        updateSpecificRole: (id, data) => dispatch(updateSpecificRole(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RoleForm' })(RoleForm));

import api from '../../utils/api';
import { NotificationManager } from 'react-notifications';

import {
    GET_LIST_ATTRIBUTES,
    GET_SPECIFIC_ATTRIBUTES,
    UPDATE_SPECIFIC_ATTRIBUTES,
    CREATE_NEW_ATTRIBUTES,
    REMOVE_ATTRIBUTES,
    UPDATE_SORT_ORDER_ATTRIBUTES
} from './types';

export const getListAttributes = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/attributes', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_ATTRIBUTES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificAttributes = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/attributes/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_ATTRIBUTES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createAttributes = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/attributes', data).then(res => {
            dispatch({ type: CREATE_NEW_ATTRIBUTES, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateSortOrder = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/attributes/update/sort-order', data).then(res => {
            dispatch({ type: UPDATE_SORT_ORDER_ATTRIBUTES, payload: res.data.data });
            //NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateAttributes = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/attributes/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_ATTRIBUTES, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeAttributes = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/attributes`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_ATTRIBUTES, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

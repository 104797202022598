import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, Drawer, Divider } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import IntlMessage from '../../components/IntlMessage';
import UploadImage from '../../components/UploadImage';
import ListCurrentImage from "../../components/ListCurrentImage";

import { createBanner, updateBanner } from '../../redux/actions/BannerAction';
import { bannerPosition, processSingleImageUpload } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications';

class BannerForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            image: [],
            currentImage: []
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.image && Array.isArray(nextProps?.currentItem?.image)) {
                this.setState({ currentImage: nextProps?.currentItem?.image })
            } else {
                this.setState({ currentImage: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.image;
            if (!err) {
                try {
                    this.setState({ isLoading: true });
                    const { image, currentImage } = this.state;
                    values.image = processSingleImageUpload(image, currentImage);
                    console.log(values);
                    if (this.props.currentItem) {
                        await this.props.updateBanner(this.props.currentItem.id, values);
                        this.onClose();
                    } else {
                        await this.props.createBanner(values);
                        //this.onClose();
                        this.setState({ isResetUpload: true, image: [], currentImage: [], isLoading: false });
                        this.props.reloadData()
                        this.props.form.resetFields();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({ isResetUpload: true, image: [], currentImage: [], isLoading: false });
        this.props.reloadData()
        this.props.onCancel();
        this.props.form.resetFields();
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Inage complete.");
    }

    getImage(data) {
        this.setState({ currentImage: data })
    }

    render() {
        const { currentImage, isResetUpload, isLoading } = this.state;
        var { visible, currentItem } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentItem ? <IntlMessage id="title.updateRecord" /> : <IntlMessage id="title.createRecord" />}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Cancel
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentItem ? "Update" : "Create"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Name">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Link">
                            {getFieldDecorator('link', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Field is required!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.link : '/'
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Position">
                            {getFieldDecorator('position', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Field is required!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.position : null
                            })(
                                <BaseRadioList
                                    options={bannerPosition}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Status">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Field is required!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Active", value: 1 },
                                        { label: "InActive", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Image" className='flex-upload border-dashed p-2'>
                            <ListCurrentImage currentImages={currentImage} getData={(data) => this.getImage(data)} />
                            {getFieldDecorator('image', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={1}
                                    onChangeData={this.onChangeImage}
                                    isResetUpload={isResetUpload}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        createBanner: (data) => dispatch(createBanner(data)),
        updateBanner: (id, data) => dispatch(updateBanner(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'BannerForm' })(BannerForm));

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Divider, Row, Col, Modal } from 'antd';
import { } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import IntlMessage from "../../components/IntlMessage";
import BaseRadioList from "../../components/Elements/BaseRadios";
import { updateAttributeGroups, createAttributeGroups } from "../../redux/actions/AttributeGroupAction";
import { baseStatus } from '../../utils/helpers';

class AttributeGroupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    handleSave(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentData) {
                        this.props.updateAttributeGroups(this.props.currentData.id, values).then(() => {
                            this.props.reloadData();
                            this.onClose();
                        });

                    } else {
                        this.props.createAttributeGroups(values).then(() => {
                            this.props.reloadData();
                            this.onClose();
                        });
                    }
                    console.log(values)

                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose = () => {
        this.props.form.resetFields();
        this.setState({ isLoading: false });
        this.props.onCancel();
    }

    render() {
        const { isLoading } = this.state;
        const { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Modal
                    visible={visible}
                    title={currentData ? <IntlMessage id="title.updateRecord" /> : <IntlMessage id="title.createRecord" />}
                    width={isMobile ? '100%' : '45%'}
                    onCancel={() => this.onClose()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.onClose()}>
                                Cancel
                            </Button>
                            <Divider type='vertical' />
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.handleSave(e)}
                            >
                                {currentData ? `Update` : 'Add new'}
                            </Button>
                        </div>
                    }
                >
                    {visible && (
                        <>
                            <Form layout='vertical'>
                                <Row gutter={16} className='mb-3'>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Group name">
                                            {getFieldDecorator('name', {
                                                initialValue: currentData ? currentData.name : '',
                                                rules: [{ required: true, message: 'Please input the group name!' }],
                                            })(
                                                <Input placeholder="Group Name" className='w-100' />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Status">
                                            {getFieldDecorator('status', {
                                                initialValue: currentData ? currentData.status : 1
                                            })(
                                                <BaseRadioList
                                                    options={baseStatus}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAttributeGroups: (id, data) => dispatch(updateAttributeGroups(id, data)),
        createAttributeGroups: (data) => dispatch(createAttributeGroups(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "AttributeGroupForm" })(AttributeGroupForm));


import api from '../../utils/api';
import { NotificationManager } from 'react-notifications';

import {
    GET_LIST_POST_CATEGORIES,
    GET_SPECIFIC_POST_CATEGORIES,
    UPDATE_SPECIFIC_POST_CATEGORIES,
    CREATE_NEW_POST_CATEGORIES,
    REMOVE_POST_CATEGORIES,
    GET_POST_CATEGORIES_ACTIVE
} from './types';

export const getListCategories = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/post-categories', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_POST_CATEGORIES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCategories = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/post-categories/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_POST_CATEGORIES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getCategoriesActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/post-categories/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_POST_CATEGORIES_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCategories = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/post-categories', data).then(res => {
            dispatch({ type: CREATE_NEW_POST_CATEGORIES, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateCategories = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/post-categories/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_POST_CATEGORIES, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCategories = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/post-categories`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_POST_CATEGORIES, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

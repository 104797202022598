import React from 'react';
import {
    ApartmentOutlined,
    SettingOutlined,
    UserOutlined,
    DashboardOutlined,
    ShoppingOutlined,
    GlobalOutlined,
    LayoutOutlined,
    TagOutlined
} from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const menu = [
    {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardOutlined className="sidebar-icon" />,
        permission: null
    },
    {
        path: '#',
        name: 'Setting',
        icon: <SettingOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/config',
                name: 'System Configuration',
                permission: 'config'
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Users & Permissions',
        icon: <ApartmentOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/users',
                name: 'Users',
                permission: 'user'
            },
            {
                path: '/roles',
                name: 'Roles',
                permission: 'roles'
            },
            {
                path: '/permissions',
                name: 'Permissions',
                permission: 'permissions'
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Products',
        icon: <ShoppingOutlined className="sidebar-icon" />,
        permission: 'products',
        children: [
            {
                path: '/products',
                name: 'All Products',
                permission: 'products',
            },
            {
                path: '/product-categories',
                name: 'Categories',
                permission: 'product_categories',
            },
            {
                path: '/attributes',
                name: 'Attributes',
                permission: 'attributes',
            },
            {
                path: '/attribute-groups',
                name: 'Attribute Groups',
                permission: 'attribute_groups',
            }
        ]
    },
    {
        path: '#',
        name: 'Posts',
        icon: <GlobalOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/posts',
                name: 'All Posts',
                permission: 'posts',
            },
            {
                path: '/post-categories',
                name: 'Categories',
                permission: 'post_categories',
            }
        ]
    },
    {
        path: '/tags',
        name: 'Tags',
        icon: <TagOutlined className="sidebar-icon" />,
        permission: 'tags'
    },
    {
        path: '#',
        name: 'Appearance',
        icon: <LayoutOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/manage-menus',
                name: 'Menus',
                permission: 'manage_menus',
            },
            {
                path: '/banner',
                name: 'Banner',
                permission: 'banner',
            },
        ]
    },
    {
        path: '/profile',
        name: 'Account Profile',
        icon: <UserOutlined className="sidebar-icon" />,
        permission: null,
    }
];

export default menu;